

export const urlBase="https://bac.varascundo.com/api"
export const urlBase1="https://bac.varascundo.com"
// https://bac.varascundo.com http://localhost:8001
export const UrlPeintureDigital=`${urlBase1}/api/oeuvres`
export const UrlCategorie=`${urlBase}/categorie `

export const UrlTheme=`${urlBase}/themes `
export const URLimage=`${urlBase1}/images/oeuvre`
export const URLOeuvreId=`${urlBase1}/api/oeuvres`
export const URLPageAccueil=`${urlBase1}/api/page_accueils`

export const URLImageAccueil=`${urlBase1}/images/Accueil/`
